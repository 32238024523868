<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="user.avatar ? '' : 'primary'"
            :class="user.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              :src="require(`@/assets/images/avatars/3.png`)"
            ></v-img>
          </v-avatar>

          <span class="mb-2">{{ `${user.name}` }}</span>

          <v-chip
            v-if="user.rank !== undefined"
            label
            small
            :class="`v-chip-light-bg text-sm font-weight-semibold`"
          >
            {{ user.rank }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Username:</span>
              <span class="text--secondary">{{ user.username }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email Address:</span>
              <span class="text--secondary">{{ user.email }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import get from '@/composables/get'

export default {
  props: ['uniqueId'], 

  setup(props, { emit }) {
    const user = ref({})
    const loading = ref(false)

    const getUserDetails = async () => {
      await get(`${process.env.VUE_APP_URI}/api/stockist/account/detail`, user, loading)
      emit('passUserData', user.value)
    }

    getUserDetails()

    watch(() => props.uniqueId, () => {
      getUserDetails()
    })

    return {
      user,
      loading,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
